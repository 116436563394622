<template>
  <div>
    <Title />
    <div class="app-content-box bg-gray profile-comments">
      <div class="container wrapper-book">
        <h2 class="top-heading-big">
          Pożycz książkę
        </h2>

        <div class="row mt-4">
          <div class="col-6">
            <img
              :src="book.image ? coverUrl + book.image : coverUrl + 'no_cover.png'"
              alt=""
              class="w-100"
            />
          </div>
          <div class="col-6 pl-0">
            <router-link :to="{name: 'BooksDetails', params: {id: book.id}}">
               <h3 class="book-side-title">{{book.tytul}}</h3>
            </router-link>
            <router-link :to="{name: 'Authors', params: {id: book.author_id}}">
              <p class="book-side-author">{{book.autor}}</p>
            </router-link>
            <div class="stars-wrapper mt-2" v-if="book.rating > 0">
              <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
              inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
              :border-width='borderWidth' :show-rating="false" :read-only="true"
              :rounded-corners='rounded' :rating="parseFloat(book.rating, 10)" >
              </star-rating>
            </div>
            <div class="rating-size mt-2" v-if="book.rating > 0">Ocena: {{avgRating}}</div>
          </div>
        </div>
      </div>
       <div class="book-form-container mt-3">
        <div class="form-group form-group-border">
          <div v-if="!personLend">
            <div class="container">
            <div class="filter-wrapper pt-3 pb-3 mb-3">
              <input type="text" name="filterFriends" class="input-filter-friends" placeholder="Wyszukaj znajomych" v-model="search"/>
              <div class="ml-3"><i class="fas fa-search"></i></div>
            </div>
            <div class="share-shelf-wrapper">
              <div v-for="(person, key) in filtered" :key="key">
                <div class="share-shelf-list" @click="selected(person)">
                    <div class="mr-3">
                      <img :src="person.thumb_url ? plainUrl + person.thumb_url : require('../assets/avatar-placeholder.jpg')" :alt="person.username" class="share-shelf-avatar"/>
                    </div>
                    <span class="share-shelf-name">{{person.username}}</span>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div>
            <div class="">
            <div class="container selected-wrapper pt-3 mb-3" v-if="personLend">
            <img :src="personLend.thumb_url ? plainUrl + personLend.thumb_url : require('../assets/avatar-placeholder.jpg')" :alt="personLend.username" />
              <input type="text" name="filterFriends" class="" v-model="personLend.username"/>
                <span class="ml-2" @click="personLend = ''">&#10006;</span>
              </div>
              <ValidationObserver ref="observer" tag="form">
                <div class="container selected-information">
                <div class="lend-title mb-2 mt-3"><b>Podaj dane</b> (obowiązkowe)</div>
                <ValidationProvider rules="required" v-slot="{errors}"  class="w-100" v-if="!personLend">
                  <input type="text" v-model="fname" placeholder="Imię" />
                  <span class="form-error-info text-center">{{ errors[0] }}</span>
                </ValidationProvider>
                  <ValidationProvider rules="required" v-slot="{errors}"  class="w-100" v-if="!personLend">
                    <input type="text" v-model="lname" placeholder="Nazwisko" />
                    <span class="form-error-info text-center">{{ errors[0] }}</span>
                  </ValidationProvider>
                    <div class="w-100 d-flex justify-content-between">
                    <ValidationProvider
                      :rules="{
                        required: true,
                        regex: /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/,
                        checkDate: true }"
                      v-slot="{errors}"  class="w-100">
                      <input type="text" placeholder="rrrr-mm-dd" class="w-100" v-model="date"/>
                      <span class="form-error-info text-center">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div class="form-calendar-icon ml-3" @click="selectCalendar = !selectCalendar"></div>
                    </div>
                    <div class="d-flex justify-content-center pb-3">
                      <DatePicker
                      mode="single"
                      v-model="date"
                      color="gray"
                      v-show="selectCalendar"
                      :model-config="modelConfig"
                      :min-date="new Date(this.today)"
                      >
                      </DatePicker>
                    </div>
                </div>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
      <ModalSlot title="Dodaj książkę" v-if="isPopupShown">
        <div>Ta książka jest już wypożyczona</div>
        <div><a class="btn-black-full w-100 mt-2 mb-2" @click="close">Wróć</a></div>
      </ModalSlot>
    <div class="form-group form-group-footer wrapper mb-5">
      <div class="container form-container">
        <button type="submit" class="btn-black-full w-100 mt-5" @click="addBookToShelf">Zapisz</button>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import Title from '../components/Title'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import StarRating from 'vue-star-rating'
import ModalSlot from '../components/ModalSlot'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, regex } from 'vee-validate/dist/rules'
export default {
  name: 'BorrowBook',
  props: {
    id: [String, Number]
  },
  components: {
    Title,
    DatePicker,
    ValidationObserver,
    ValidationProvider,
    StarRating,
    ModalSlot
  },
  async created () {
    let tempdate = new Date()
    let day = tempdate.getDate()
    if (tempdate.getDate() < 10) {
      day = '0' + day
    }
    tempdate = tempdate.getFullYear() + '-' + (tempdate.getMonth() + 1) + '-' + day
    extend('required', {
      ...required,
      message: 'Pole jest obowiązkowe'
    })
    extend('regex', {
      ...regex,
      message: 'Poprawny format danych to: rrrr-mm-dd'
    })
    extend('checkDate', {
      validate: value => value > tempdate,
      message: 'Data musi być datą przyszłą'
    })

    await this.$https('/book/' + this.id, {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(({ data }) => {
        this.book = data.response.book
        this.avgRating = data.response.book.rating
        if (this.avgRating % 1 === 0) {
          this.avgRating = parseFloat(data.response.book.rating, 10)
        } else {
          this.avgRating = parseFloat(data.response.book.rating, 10)
          this.avgRating = this.avgRating.toFixed(1)
        }
      })
      .catch(error => {
        console.log('', error)
      })

    this.today = new Date()
    this.today = this.today.getFullYear() + '-' + (this.today.getMonth() + 1) + '-' + this.today.getDate()
    this.today = this.today.replace(/-/gi, ', ')
    this.today = this.today.replace(/, 0/gi, ', ')
  },
  computed: {
    filtered () {
      if (this.search.length < 3) return ''
      else {
        return this.friendsList.filter(x => {
          return x.username.toLowerCase().includes(this.search.toLowerCase())
        })
      }
    }
  },
  data () {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD' // Uses 'iso' if missing
      },
      date: '',
      today: '',
      isPopupShown: false,
      tempShelfArray: [],
      selectCalendar: false,
      fname: '',
      lname: '',
      check_id: 0,
      book: [],
      avgRating: 0,
      personLend: '',
      shelves: [],
      topShelves: [],
      checkedShelves: [],
      friendsList: [],
      name: '',
      surname: '',
      search: '',
      checkedStatus: 'want',
      selectedDate: '',
      isBookLend: false,
      isBookOwn: false,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 18,
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: new Date()
        }
      ]
    }
  },
  watch: {
    search: function (val) {
      if (val.length > 2) {
        this.filterUsers(val)
      }
    }
  },
  methods: {
    close () {
      this.isPopupShown = false
      this.$router.go(-1)
    },
    selected (person) {
      this.personLend = person
    },
    filterUsers (item) {
      this.$https('/search/user?user=' + item, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(({ data }) => {
          this.friendsList = data.response
        })
        .catch(error => {
          console.log('', error)
        })
    },

    async addBookToShelf () {
      const valid = await this.$refs.observer.validate()
      if (valid === true) {
        if (this.personLend) {
          const lendData = `user=${this.personLend.id}&date=${this.date}&fname=${this.fname}&lname=${this.lname}`
          this.$https('/book/' + this.id + '/lend', {
            method: 'POST',
            mode: 'no-cors',
            crossDomain: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: lendData
          }).then(response => {
            if (response.data.message === 'book already lent') {
              this.isPopupShown = true
            } else {
              this.$router.go(-1)
            }
          })
            .catch(error => {
              console.log('', error)
            })
        } else {
          const lendData = `date=${this.date}&fname=${this.fname}&lname=${this.lname}`
          this.$https('/book/' + this.id + '/lend', {
            method: 'POST',
            mode: 'no-cors',
            crossDomain: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: lendData
          }).then(response => {
            if (response.data.message === 'book already lent') {
              this.isPopupShown = true
            } else {
              this.$router.go(-1)
            }
          })
            .catch(error => {
              console.log('', error)
            })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
  .wrapper-book {
    padding-top: 30px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
}
  .filter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-filter-friends {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    font-size: 14px;

    &:active, &:focus {
      font-size: 14px;
    }
  }
  .share-shelf {
    &-wrapper {
    width: 100%;
    max-height: 50vh;
    overflow: scroll;
    }
    &-list {
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
    &-avatar {
      width: 50px;
      height: 50px;
    }
  }
  .book-side-option-icon {
      width: 100%;
      display: flex;
      justify-content: center;
  }
  .selected-wrapper {
    // padding: 0 15px 10px 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    input {
      width: 70%;
      border: none;
      border-bottom: 1px solid black;
      font-size: 14px;

      &:active, &:focus {
        font-size: 14px;
      }
    }
  }
  .selected-information {
    background-color: #fff;
  input {
    width: 100%;
    margin-bottom: 20px;
    border: none;
    border-bottom: 1px solid black;
    font-size: 14px;

    &:active, &:focus {
      font-size: 14px;
    }
  }
}
.form-error-info {
  margin: 0;
  line-height: 14px;
}
.lend-title {
  font-size: 14px;
}

.form-calendar-icon {
  position: relative;

  &::before {
    position: relative;
    height: 40px;
    width: 40px;
    text-align: center;
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
    content: '\f073';
    color: #000;
    // right: 0;
    // top: 0;
    z-index: 2;
  }

  .form-input {
    position: relative;
    padding-right: 40px;
    display: block;
    width: 100%;
    background-color: none;
    z-index: 1;
  }
}
  .classes {
   border-bottom: 1px solid red;
  }

  .stars-wrapper {
    display: flex;
    justify-content: flex-start;
  }
  .share-shelf-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .rating-size {
    font-size: 14px;
  }
</style>
